import * as Sentry from '@sentry/gatsby'

// Sentry.init({
//   dsn: 'https://e1ecf7066e0240309bb37f0c99667a1d@o191317.ingest.sentry.io/4504136679030784',
//   environment: process.env.NODE_ENV,
//   debug: process.env.NODE_ENV === 'production',
//   enabled: process.env.NODE_ENV === 'production',
//   sampleRate: 0.2,
// })

Sentry.init({
  dsn: 'https://82e0741a868049fd5560c89250ce7bec@o4506859562205184.ingest.us.sentry.io/4506859619155968',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  beforeSend: (event, hint) => {
    if (hint?.originalException instanceof Error) {
      const error = hint.originalException
      if (
        error?.stack &&
        ['jquery-3.4.1.min.js', 'gtm.js'].some((scriptName) =>
          error.stack.includes(scriptName)
        )
      ) {
        return null
      }
    }
    return event
  },
  debug: process.env.NODE_ENV === 'production',
  enabled: false,
  tracesSampleRate: 0.2, //  Capture 100% of the transactions
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})
