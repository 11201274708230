import { lazy, Suspense, useEffect } from 'react'
import type { ReactElement } from 'react'
import { useQueryParam, StringParam } from 'use-query-params'
import { Helmet } from 'react-helmet-async'
import { useUI } from 'src/sdk/ui'
import Header from 'src/components/common/Header'
import { useMobile } from 'src/hooks/useMobile'
import { CepInformationProvider } from 'src/components/common/CepComponent/hooks/CepInformationContext'
import { useLocation } from '@reach/router'

import { usePageViewEvent } from './utils/gtmEvents'
import { UserDataProvider } from './components/contexts/UserDataContext'
import PopUpAppsflyer from './components/common/PopUpAppsflyer'
import { ThemeProvider } from './components/contexts/ThemeContext'
import { useLinxOtherView } from './sdk/linx/hooks/useLinxOtherView'
import PopUpCookies from './components/common/PopUpCookies'
import { GlobalProvider } from './components/global/context/global-context'
import Footer from './components/restructure/common/Footer'
import AccountFooter from './components/account/Footer'
import { AccountProvider } from './components/account/context'
import { WishlistProvider } from './components/contexts/wishlist-context'
import { checkEnviromentIsB2B } from './utils/checkEnviroment'
import { CheckoutProvider } from './sdk/checkout/context/checkout'

const CartSidebar = lazy(() => import('src/components/cart/CartSidebar'))

type LayoutProps = {
  children: ReactElement
}

function Layout({ children }: LayoutProps) {
  const isB2B = checkEnviromentIsB2B()
  const { displayMinicart } = useUI()
  const { screenWidth } = useMobile()
  const { sendOtherViewEvent } = useLinxOtherView()
  const [mode] = useQueryParam('mode', StringParam)
  const { pathname, href } = useLocation()

  const hideHeader = ['member', 'checkout', 'login'].some((item) =>
    pathname.includes(item)
  )

  const isAccountFooter = pathname.includes('account')

  const url = href ? new URL(href) : null

  const paramOrderFormId = url?.searchParams.get('orderFormId')

  usePageViewEvent()

  useEffect(() => {
    if (!children) {
      return
    }

    const pathPage = (children as ReactElement)?.props?.children?.[1]?.key

    sendOtherViewEvent(pathPage)
  }, [sendOtherViewEvent, children])

  useEffect(() => {
    const el = document.getElementsByClassName('chatbot-sc-launcher')

    if (pathname !== '/atendimento' && el.length > 0) {
      el[0].remove()
    }
  }, [pathname])

  return (
    <>
      <Helmet>
        <meta
          property="og:image"
          content="https://decathlonstore.vteximg.com.br/arquivos/logo_site_tag_og2.png"
        />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=5"
        />
        <meta
          property="og:site_name"
          content="Decathlon a maior loja de artigos esportivos"
        />
      </Helmet>
      <UserDataProvider>
        <ThemeProvider>
          <CepInformationProvider>
            <GlobalProvider>
              <AccountProvider>
                <WishlistProvider>
                  <CheckoutProvider paramOrderFormId={paramOrderFormId}>
                    {mode !== 'app' && !hideHeader && <Header />}

                    <>{children}</>

                    {mode !== 'app' && !hideHeader && (
                      <>{isAccountFooter ? <AccountFooter /> : <Footer />}</>
                    )}

                    {screenWidth <= 1024 && isB2B === false && (
                      <PopUpAppsflyer />
                    )}

                    <PopUpCookies />

                    <noscript>
                      <iframe
                        src="https://www.googletagmanager.com/ns.html?id=GTM-T777CVM"
                        height="0"
                        width="0"
                        style={{ display: 'none', visibility: 'hidden' }}
                        title="Google Tag Manager iframe"
                      />
                    </noscript>

                    {displayMinicart && (
                      <Suspense fallback={null}>
                        <CartSidebar />
                      </Suspense>
                    )}
                  </CheckoutProvider>
                </WishlistProvider>
              </AccountProvider>
            </GlobalProvider>
          </CepInformationProvider>
        </ThemeProvider>
      </UserDataProvider>
    </>
  )
}

export default Layout
